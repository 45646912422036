import { useState } from "react";
//import { useHistory } from "react-router-dom";
import classes from './Tests.module.css';
import QRCode from 'react-qr-code';

const Survey = () => {
  
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [surveyTopic, setSurveyTopic] = useState("");
  const [surveyScore, setSurveyScore] = useState("");  
  const [surveyComment, setSurveyComment] = useState("");  
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [dataFlask, setDataFlask] = useState("");

  //const [isPending, setIsPending] = useState(false);
  //const history = useHistory();

  const handleSurveyTopic = (event) => {
    setSurveyTopic(event.target.value);
  };

  const handleSurveyScore = (event) => {
    setSurveyScore(event.target.value);
  };

	const handleSubmission = () => {

		fetch(
			'https://flask-image-surveys-510500279206.us-central1.run.app/surveyInput',
			{
				method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                  },
				body: JSON.stringify({"name": name, "email": email, "surveyTopic": surveyTopic, "surveyScore": surveyScore, "surveyComment": surveyComment}),
			}
		)
			.then((response) => response.json())
			.then((dataFlask) => {
				setDataFlask(dataFlask);
                setIsSubmitted(true);
			  })
			.catch((error) => {
				console.error('Error:', error);
			});
	  
	};

  return (
    <div className={classes.create}>
      <h1>
        Please Provide a Feedback
      </h1>
      <div>
          <label>Name:</label>
          <input
            type="text"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          </div>           
        <div>
          <label>Email:</label>
          <input
            type="text"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          </div>  
          <div className="select">
      <label>Topic:</label>
      <select value={surveyTopic} onChange={handleSurveyTopic}>
        <option value="--select--">--select--</option>
        <option value="Master Class on Gen AI Applications - CMS - Jain University">Master Class on Gen AI Applications - CMS - Jain University</option>
        <option value="Business Analytics Workshop - CMS - Jain University - 4th Semester BBA">Business Analytics Workshop - CMS - Jain University - 4th Semester BBA</option>
        <option value="General">General</option>
      </select>
      </div>
      <div className="select">
      <label>Feedback Score:</label>
      <select value={surveyScore} onChange={handleSurveyScore}>
        <option value="--select--">--select--</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
      </select>
      </div>
      <div>
          <label>Please provide some comments:</label>
          <input
            type="text"
            required
            value={surveyComment}
            onChange={(e) => setSurveyComment(e.target.value)}
          />
          </div>  
          <p></p>
        <div>
      {isSubmitted && (<p>{dataFlask["value"]}</p>)}
      </div>
      <div>
        <button onClick={handleSubmission}>Submit Your Feedback</button>
      </div>
      <p></p>
      <p><h3>Scan this below QR code to reach here</h3></p>
      <p></p>
      <div>
                <QRCode
                        title="Feedback"
                        value="https://arijitsarkar.in/survey"
                    />
                </div>
      <p><h3>Thank You!!</h3></p>
    </div> 
  );
};

export default Survey;